











































































































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import dayjs from 'dayjs';
import { IFundRecommendationReportDto } from '@/interfaces/dto/IFundRecommendationReportDto';
import RecommendedFundSelector from '@/components/RecommendedFundSelector.vue';
import { IResearchView } from '@/interfaces/entity/IClientEntity';
import { IFundLatestGradeDto } from '@/interfaces/dto/IFundLatestGradeDto';
import { cloneDeep } from 'lodash';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import { IFundRecommendationUsers } from '@/interfaces/dto/IFundRecommendationUsers';

@Component({
    components: {
        DatePicker,
        RecommendedFundSelector,
        BaseModal,
        BaseButton
    },
})
export default class FundRecommendationReport extends Vue {
@Prop({type: Object, required: true }) fundRecommendationReport: IFundRecommendationReportDto | null;

@Prop({type: Object, required: true}) fundRecommendationUsers: IFundRecommendationUsers | null;

@Prop({type: String, required: true}) clientId: string;

@Prop({type: Array, required: true}) currencyOptions: string[];

@Prop({type: Boolean, required: true}) isNewReport: boolean;

@Prop({type: String, required: true}) userEmail: string;

@Prop({type: Boolean, required: true}) viewAsReadonly: boolean;

public updatedReport: IFundRecommendationReportDto | null = null

public effectiveDate = '';

public dateError = false;

public localManagerName = '';

public researchView: IResearchView = null;

public selectedManagerFund: IFundLatestGradeDto = null;

public showFundSelector = false;

public reportCurrencyOptions :string[] = [];

//Validation booleans

public isTitleBlank = false;

public isTitleTooLong = false;

public isBillingCodeInvalid = false;

public isSingleFundRecommendedInvalid = false;

public areNoFundsRecommended = false;

public isBackgroundInvalid = false;

public areSpecificRequirementsInvalid = false;

public isAssetClassLeadDiscussionInvalid = false;

public isAssetClassLeadInvalid = false;

public isNumberOfAppointmentsInvalid = false;

public isProportionOfAssetsInvalid = false;

//Sign off validation booleans

public isDiscussedWithAssetClassFalseAndSigningOff = false;

public isDateSentToClientNullAndSigningOff = false;

public isSelectedByClientNullAndSigningOff = false;

created (): void{
    this.updatedReport = cloneDeep(this.fundRecommendationReport);
    this.reportCurrencyOptions = this.currencyOptions;
}

get isSignOffPartner (): boolean {
    const userEmailLowerCase = this.userEmail.toLowerCase();
    const signOffPartnerLowerCase = this.updatedReport.auditInfo.signOffPartner.toLowerCase();
    return userEmailLowerCase === signOffPartnerLowerCase;
}

public singleFundRecommended (): boolean {
    return this.updatedReport.recommendedFunds.funds.length === 1;
}

public removeFund (index: number): void{
    this.updatedReport.recommendedFunds.funds.splice(index, 1);
}

public showFundSelectorModal (): void {
    this.showFundSelector = true;
}

public notAfterToday (date: string): boolean {
    const dateNow = dayjs();
    const thisDate = dayjs(date);
    return thisDate > dateNow;
}

public getInitialFundResearchEntityIds (): number[] {
    return this.updatedReport.recommendedFunds.funds.map(x => x.recommendation.researchEntityId);
}

updateErrorMessage (value: string): void {
    if (dayjs(value).isValid()) {
        this.dateError = false;
    }
}

public updateFunds (selectedFunds: IFundLatestGradeDto[]): void {
    const currentIds = this.getInitialFundResearchEntityIds();

    selectedFunds.forEach((selectedFund: IFundLatestGradeDto) => {
        if (!currentIds.includes(selectedFund.fundInfo.fundResearchEntityId)) {
            this.updatedReport.recommendedFunds.funds.push({
                recommendation: {
                    researchEntityId: selectedFund.fundInfo.fundResearchEntityId,
                    name: selectedFund.fundInfo.fundName,
                    assetClass: selectedFund.fundInfo.assetClass ?? 'Not Set',
                    lcpRatingWhenRecommended: selectedFund.latestGrade?.approvalStatus ?? '',
                    reasoning: ''
                },
                outcome: {
                    selectedByClient: null,
                    reasonForOutcome: null
                }
            });
        }
    });

    currentIds.forEach((fundResearchEntityId: number) => {
        if (!selectedFunds.some(x => x.fundInfo.fundResearchEntityId === fundResearchEntityId)) {
            this.updatedReport.recommendedFunds.funds = this.updatedReport.recommendedFunds.funds.filter(fund => fund.recommendation.researchEntityId !== fundResearchEntityId);
        }
    });

    this.showFundSelector = false;
}

public hideFundSelector (): void {
    this.showFundSelector = false;
}

public validateAndCreate (): void {
    const isValid = this.validate(false);

    if (isValid) {
        this.emitSaveNew();
    }
    else {
        this.$store.dispatch('pushNetworkValidationMessage', 'The form failed validation. Please check the highlighted inputs');
    }
}

public validateAndUpdate (): void {
    const isValid = this.validate(false);

    if (isValid) {
        this.emitUpdate();
    }
    else {
        this.$store.dispatch('pushNetworkValidationMessage', 'The form failed validation. Please check the highlighted inputs');
    }
}

public validateAndSignOff (): void {
    const isValid = this.validate(true);

    if (isValid) {
        this.emitSignOff();
    }
    else {
        this.$store.dispatch('pushNetworkValidationMessage', 'The form failed validation. Please check the highlighted inputs');
    }
}

public validate (isSignOff: boolean): boolean {
    const report = this.updatedReport;
    const notes = report.notes;
    const searchSize = report.approximateSizeOfSearch;
    const funds = report.recommendedFunds;
    const auditInfo = report.auditInfo;

    this.isTitleBlank = report.title.trim() === '';
    this.isTitleTooLong = report.title.length > 100;
    this.isBillingCodeInvalid = report.clientBillingCode.trim() === '';
    this.isBackgroundInvalid = notes.background.trim() === '';
    this.areSpecificRequirementsInvalid = notes.specificRequirements.trim() === '';
    this.isSingleFundRecommendedInvalid = funds.funds.length === 1 && funds.reasoningForASingleFund.trim() === '';
    this.areNoFundsRecommended = funds.funds.length === 0;
    this.isAssetClassLeadInvalid = auditInfo.discussedWithAssetClassLead && auditInfo.assetClassLead === '';
    this.isAssetClassLeadDiscussionInvalid = auditInfo.discussedWithAssetClassLead && auditInfo.assetClassLeadDiscussion.trim() === '';
    this.isNumberOfAppointmentsInvalid = !Number.isInteger(Number(report.expectedNumberOfAppointments)) || report.expectedNumberOfAppointments < 1 || report.expectedNumberOfAppointments > 10;
    this.isProportionOfAssetsInvalid = searchSize.allocation < 0 || searchSize.allocation > 100;
    this.isDiscussedWithAssetClassFalseAndSigningOff = auditInfo.discussedWithAssetClassLead === false && isSignOff;
    this.isDateSentToClientNullAndSigningOff = report.dates.actualSendDate === null && isSignOff;
    this.isSelectedByClientNullAndSigningOff = funds.funds.some(x => x.outcome.selectedByClient === null) && isSignOff;

    return !(
        this.isTitleBlank ||
        this.isTitleTooLong ||
        this.isBillingCodeInvalid ||
        this.isBackgroundInvalid ||
        this.areSpecificRequirementsInvalid ||
        this.isSingleFundRecommendedInvalid ||
        this.areNoFundsRecommended ||
        this.isAssetClassLeadDiscussionInvalid ||
        this.isAssetClassLeadInvalid ||
        this.isNumberOfAppointmentsInvalid ||
        this.isProportionOfAssetsInvalid ||
        this.isDiscussedWithAssetClassFalseAndSigningOff ||
        this.isDateSentToClientNullAndSigningOff ||
        this.isSelectedByClientNullAndSigningOff
    );
}

@Emit('save-new')
public emitSaveNew (): IFundRecommendationReportDto {
    return this.updatedReport;
}

@Emit('sign-off')
public emitSignOff (): IFundRecommendationReportDto {
    return this.updatedReport;
}

@Emit('update')
public emitUpdate (): IFundRecommendationReportDto {
    return this.updatedReport;
}

@Emit('close')
public handleCancel (): void {
    return;
}

}
