var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    Object.keys(_vm.fundRecommendationReports).length > 0
      ? _c("table", [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(_vm.fundRecommendationReports, function (report, index) {
              return _c(
                "tr",
                { key: index + "-" + report.dates.targetSendDate },
                [
                  _c("td", [_vm._v(_vm._s(report.title))]),
                  _c("td", { staticClass: "no-wrap" }, [
                    _vm._v(
                      _vm._s(
                        _vm
                          .dayJs(report.dates.targetSendDate)
                          .format("DD MMM YYYY")
                      )
                    ),
                  ]),
                  _c("td", [_vm._v(_vm._s(report.auditInfo.contact))]),
                  _c("td", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getStatusString(report.status))),
                    ]),
                  ]),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _c("BaseButton", {
                          attrs: { type: "button", tooltipText: "View" },
                          on: {
                            click: function ($event) {
                              return _vm.showDetail(index, report, true)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      staticClass: "action-icon",
                                      attrs: { icon: ["fal", "info-circle"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        !_vm.isSignedOff(report)
                          ? _c("BaseButton", {
                              attrs: { type: "button", tooltipText: "Edit" },
                              on: {
                                click: function ($event) {
                                  return _vm.showDetail(index, report, false)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("font-awesome-icon", {
                                          staticClass: "action-icon",
                                          attrs: { icon: ["fal", "edit"] },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                        !_vm.isSignedOff(report)
                          ? _c("BaseButton", {
                              attrs: { type: "button", tooltipText: "Delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.showConfirmDeleteModal(
                                    index,
                                    report
                                  )
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("font-awesome-icon", {
                                          staticClass: "action-icon",
                                          attrs: { icon: ["fal", "trash-alt"] },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ])
      : _c("p", { staticClass: "no-reports-text" }, [
          _vm._v("No reports found."),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Title")]),
        _c("th", [_vm._v("Target Date")]),
        _c("th", [_vm._v("Created By")]),
        _c("th", [_vm._v("Status")]),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }