




























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IFundLatestGradeDto } from '@/interfaces/dto/IFundLatestGradeDto';
const SHOW_COUNT = 20;
const SHOW_COUNT_INCREMENT = 20;
@Component({})
export default class RecommendedFundSelector extends Vue {
    @Prop({ required: true, type: Array }) initialFundResearchEntityIds: number[];

    public fundsWithLatestGrade: Array<IFundLatestGradeDto> = [];

    public selectedFunds: Array<IFundLatestGradeDto> = [];

    public loading = false;

    public deleteFund = false;

    displayIssue = '';

    public search = '';

    public BUY_FILTER = 'Buy';

    public BUY_AND_HOLD_FILTER = 'BuyHold';

    public ALL_FILTER = 'All';

    public radioFilter = this.BUY_FILTER;

    public SHOW_COUNT = Object.freeze(SHOW_COUNT);

    public SHOW_COUNT_INCREMENT = Object.freeze(SHOW_COUNT_INCREMENT);

    public showCount = SHOW_COUNT;

    get showCountText (): number{
        return this.showCount < this.filteredListLength ? this.showCount : this.filteredListLength;
    }

    increaseCount (): void {
        this.showCount += SHOW_COUNT_INCREMENT;
    }

    updateShowCount (): void {
        this.showCount = SHOW_COUNT;
    }

    get showMoreButton (): boolean {
        return this.showCount < this.filteredListLength;
    }

    get filteredListLength (): number {
        return this.filteredList.length;
    }


    // Search functions

    stringNormalizeNoSpaces (value: string): string{
        if (value === null) {
            return '';
        }
        return value.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase().trim();
    };

    stringNormalizeWithSpaces (value: string): string {
        if (value === null) {
            return '';
        }
        // Normalize: remove non-alphanumeric characters except spaces, convert to lowercase, trim, and collapse multiple spaces into one.
        return value.replace(/[^a-zA-Z0-9 ]+/g, '').toLowerCase().trim().replace(/\s+/g, ' ');
    };

    basicSearchFn (input: string, searchBy: string): boolean {
        let searchByNormalized = this.stringNormalizeNoSpaces(searchBy);
        return searchByNormalized.includes(this.stringNormalizeNoSpaces(input));
    };

    // search function to cover the case when user searches, for example, one word from manager, one word from fund
    matchesBoth (input: string, searchBy: string): boolean {
        let combinedNormalizedInput = this.stringNormalizeWithSpaces(input).split(' ');
        let normalizedSearchByWords = this.stringNormalizeWithSpaces(searchBy).split(' ');

        return combinedNormalizedInput.every(inputWord => normalizedSearchByWords.some(searchByWord => searchByWord.startsWith(inputWord)));
    };

    isFoundSearchFn (input: string, searchBy: string): boolean {
        return this.basicSearchFn(input, searchBy) || this.matchesBoth(input, searchBy);
    };

    get filteredList (): Array<IFundLatestGradeDto> {
        const filteredByRadioFilterList = this.filterBySelectedRadio();

        return filteredByRadioFilterList.filter((fund) => {
            let unitedFundManagerString = `${fund.fundInfo.fundName} ${fund.fundInfo.managerName}`;
            return this.isFoundSearchFn(this.search, unitedFundManagerString);
        });
    }

    private filterBySelectedRadio (): Array<IFundLatestGradeDto> {
        if( this.radioFilter === this.BUY_FILTER ){
            return this.fundsWithLatestGrade.filter((fund) => {
                return fund.latestGrade?.approvalStatus === 'Buy';
            });
        }
        else if( this.radioFilter === this.BUY_AND_HOLD_FILTER ){
            return this.fundsWithLatestGrade.filter((fund) => {
                return fund.latestGrade?.approvalStatus === 'Buy' || fund.latestGrade?.approvalStatus === 'Hold';
            });
        }

        return this.fundsWithLatestGrade;
    }

    public changeActiveFilter (newFilter: string): void {
        this.radioFilter = newFilter;
    }

    created (): void {
        this.fundsWithLatestGrade = this.$store.state.fundsWithLatestGrade;
        this.selectedFunds = this.getInitialFunds();
    }

    private getInitialFunds (): Array<IFundLatestGradeDto> {
        return this.fundsWithLatestGrade.filter(x => this.initialFundResearchEntityIds.includes(x.fundInfo.fundResearchEntityId));
    }

    public updateFund (newFund: IFundLatestGradeDto): void {
        if (this.selectedFunds.every(selectedFund => selectedFund.fundInfo.fundId !== newFund.fundInfo.fundId)) {
            this.selectedFunds.push(newFund);
        }
    }

    public deleteAssociation (id: number): void {
        this.deleteFund = true;
        const index = this.selectedFunds.findIndex(fund => fund.fundInfo.fundResearchEntityId === id);

        if (index !== -1) {
            this.selectedFunds.splice(index, 1);
        }
    }

    public updateFunds (): void {
        if (this.deleteFund) this.$emit('delete-association');
        if (!this.selectedFunds) this.$emit('close-modal');
        else this.$emit('update-funds', this.selectedFunds);
    }

    get activeSelection (): IFundLatestGradeDto[] {
        if (this.selectedFunds) return this.selectedFunds;
        return [];
    }
}

