import { IFundRecommendationReportDto } from '@/interfaces/dto/IFundRecommendationReportDto';
import { IFundRecommendationUsers } from '@/interfaces/dto/IFundRecommendationUsers';
import ApiHelper from './apiHelper';

export default class FundRecommendation {
    static async getFundRecommendations (clientId: string): Promise<{ [key: number]: IFundRecommendationReportDto }> {
        return (await ApiHelper.get(`GetAllClientFundRecommendations/${clientId}`)).data as { [key: number]: IFundRecommendationReportDto };
    }

    static async createFundRecommendation (fundRecommendationExercise: IFundRecommendationReportDto): Promise<{ [key: number]: IFundRecommendationReportDto }> {
        return (await ApiHelper.post('CreateFundRecommendation', { fundRecommendationExercise: fundRecommendationExercise })).data as { [key: number]: IFundRecommendationReportDto };
    }

    static async updateFundRecommendation (exerciseId: number, fundRecommendationExercise: IFundRecommendationReportDto): Promise<{ [key: number]: IFundRecommendationReportDto }> {
        return (await ApiHelper.post('UpdateFundRecommendation', { exerciseId: exerciseId, fundRecommendationExercise: fundRecommendationExercise })).data as { [key: number]: IFundRecommendationReportDto };
    }

    static async deleteFundRecommendation (exerciseId: number): Promise<{ [key: number]: IFundRecommendationReportDto }> {
        return (await ApiHelper.post('DeleteFundRecommendation', { exerciseId: exerciseId })).data as { [key: number]: IFundRecommendationReportDto };
    }

    static async signOffFundRecommendation (exerciseId: number, userEmail: string): Promise<{ [key: number]: IFundRecommendationReportDto }> {
        return (await ApiHelper.post('SignOffFundRecommendation', { exerciseId: exerciseId, userEmail: userEmail })).data as { [key: number]: IFundRecommendationReportDto };
    }

    static async getUserEmail (): Promise<string> {
        return (await ApiHelper.get('GetUserEmail')).data as string;
    }

    static async getFundRecommendationUsersForClient (clientId: string): Promise<IFundRecommendationUsers> {
        return (await ApiHelper.get(`GetFundRecommendationUsersForClient/${clientId}`)).data as Promise<IFundRecommendationUsers>;
    }
}