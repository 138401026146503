var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recommended-funds-wrapper" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "loader-container" },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fal", "spinner"], spin: true },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "content-box" }, [
          _c("div", { staticClass: "left-section" }, [
            _c("div", { staticClass: "search-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.search,
                    expression: "search",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "search",
                attrs: {
                  type: "text",
                  placeholder: "Type to filter Managers, Strategies & Funds...",
                },
                domProps: { value: _vm.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value.trim()
                    },
                    _vm.updateShowCount,
                  ],
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "filters" }, [
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "buy-only",
                  name: "filter",
                  checked: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.changeActiveFilter(_vm.BUY_FILTER)
                  },
                },
              }),
              _c("label", { attrs: { for: "buy-only" } }, [
                _vm._v("Buy rated funds"),
              ]),
              _c("input", {
                attrs: { type: "radio", id: "buy-and-hold", name: "filter" },
                on: {
                  click: function ($event) {
                    return _vm.changeActiveFilter(_vm.BUY_AND_HOLD_FILTER)
                  },
                },
              }),
              _c("label", { attrs: { for: "buy-and-hold" } }, [
                _vm._v("Buy or hold rated funds"),
              ]),
              _c("input", {
                attrs: { type: "radio", id: "all", name: "filter" },
                on: {
                  click: function ($event) {
                    return _vm.changeActiveFilter(_vm.ALL_FILTER)
                  },
                },
              }),
              _c("label", { attrs: { for: "all" } }, [_vm._v("All funds")]),
            ]),
            _c("div", { staticClass: "radio-row" }, [
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.showCountText) +
                      " of " +
                      _vm._s(_vm.filteredListLength) +
                      " results"
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "selectors-row" }, [
              !_vm.filteredListLength
                ? _c("p", [
                    _vm._v(
                      "No funds match " +
                        _vm._s(_vm.search) +
                        ", please try again"
                    ),
                  ])
                : _c(
                    "div",
                    [
                      _c(
                        "transition-group",
                        { attrs: { name: "fade", mode: "out-in", tag: "div" } },
                        _vm._l(
                          _vm.filteredList.slice(0, _vm.showCount),
                          function (item) {
                            return _c(
                              "div",
                              {
                                key: item.fundInfo.fundId,
                                staticClass: "card card--list",
                                attrs: { tabindex: "0" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateFund(item)
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.updateFund(item)
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _c("p", [
                                    _vm._v(" Name: "),
                                    _c("strong", [
                                      _vm._v(_vm._s(item.fundInfo.fundName)),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _vm._v(" Manager name: "),
                                    _c("span", { staticClass: "italic" }, [
                                      _vm._v(_vm._s(item.fundInfo.managerName)),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "Research group: " +
                                        _vm._s(
                                          item.fundInfo.researchGroup ||
                                            "not set"
                                        )
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "card__rating" }, [
                                  _c("p", [
                                    _vm._v(
                                      "Approval status: " +
                                        _vm._s(
                                          item.latestGrade
                                            ? item.latestGrade.approvalStatus
                                            : "not set"
                                        )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "Asset class: " +
                                        _vm._s(
                                          item.fundInfo.assetClass
                                            ? item.fundInfo.assetClass
                                            : "not set"
                                        )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "Overall grade: " +
                                        _vm._s(
                                          item.latestGrade
                                            ? item.latestGrade.overallGrade
                                            : "not set"
                                        )
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                      _vm.showMoreButton
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn--next",
                              attrs: { type: "button" },
                              on: { click: _vm.increaseCount },
                            },
                            [
                              _vm._v(
                                "Next " +
                                  _vm._s(_vm.SHOW_COUNT_INCREMENT) +
                                  " results"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ]),
          ]),
          _c("div", { staticClass: "selection-wrapper" }, [
            _c("p", { staticClass: "selection-association" }, [
              _vm.activeSelection.length === 0
                ? _c("span", [_vm._v("No Funds Selected")])
                : _c("span", [_vm._v("Selected Funds")]),
              _vm.selectedFunds &&
              _vm.selectedFunds.length > 0 &&
              _vm.selectedFunds[0].fundInfo.managerId
                ? _c("span", { staticClass: "text-right" }, [
                    _vm._v(" (save to update) "),
                  ])
                : _vm._e(),
            ]),
            _vm.activeSelection.length > 0
              ? _c(
                  "div",
                  { staticClass: "selected-cards-container" },
                  _vm._l(_vm.activeSelection, function (selection) {
                    return _c(
                      "div",
                      {
                        key: selection.fundInfo.fundResearchEntityId,
                        staticClass: "card selected active fund-details",
                      },
                      [
                        _c("div", { staticClass: "space-between" }, [
                          _c("div", { staticClass: "flex flex--column" }, [
                            _c("p", { staticClass: "wrap" }, [
                              _vm._v("Fund: "),
                              _c("strong", [
                                _vm._v(_vm._s(selection.fundInfo.fundName)),
                              ]),
                            ]),
                            _c("p", { staticClass: "wrap" }, [
                              _vm._v("Manager: "),
                              _c("span", { staticClass: "italic" }, [
                                _vm._v(_vm._s(selection.fundInfo.managerName)),
                              ]),
                            ]),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn remove-selection",
                              attrs: { type: "button", title: "Delete Fund" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteAssociation(
                                    selection.fundInfo.fundResearchEntityId
                                  )
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fal", "trash-alt"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
    _c("div", { staticClass: "flex details-bottom" }, [
      _c(
        "div",
        {
          staticClass: "btn btn--default discard",
          on: {
            click: function ($event) {
              return _vm.$emit("close-modal")
            },
          },
        },
        [_vm._v("Cancel")]
      ),
      _c(
        "div",
        { staticClass: "btn btn--default", on: { click: _vm.updateFunds } },
        [_vm._v("Save")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }