









































import { Component, Vue } from 'vue-property-decorator';
import { IFundRecommendationReportDto } from '@/interfaces/dto/IFundRecommendationReportDto';
import dayjs from 'dayjs';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import fundRecommendationApi from '@/api/fundRecommendationApi';
import FundRecommendationReport from '@/components/MonitorManager/FundRecommendationReport.vue';
import FundRecommendationReports from '@/components/MonitorManager/FundRecommendationReports.vue';
import ConfirmDeletion from '@/components/ConfirmDeletion.vue';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import { FundRecommendationStatus } from '@/enums/FundRecommendationStatus';
import {FORMAT_DATE_ISO_SHORT} from '@/constants';
import { IFundRecommendationUsers } from '@/interfaces/dto/IFundRecommendationUsers';

@Component({
    components: {
        LoadingOverlay,
        FundRecommendationReport,
        ConfirmDeletion,
        BaseButton,
        BaseModal,
        FundRecommendationReports
    },
})

export default class FundRecommendationsView extends Vue {

    created (): void {
        this.fetchData();
    }

    public dayJs = dayjs;

    public isFetchingData = false;

    public fundRecommendationReports: { [key: number]: IFundRecommendationReportDto } = {};

    public showFundRecommendationReport = false;

    public viewAsReadonly = true;

    public selectedReport: IFundRecommendationReportDto | null = null

    public selectedExerciseId: number | null = null

    public clientId: string = this.$store.state.selectedScheme.clientEntityID

    public currencyOptions: string[] = this.$store.state.selectedScheme.currencies

    public fundRecommendationUsers: IFundRecommendationUsers | null = null;

    public userEmail = '';

    public isNewReport = true;

    deleteModal = {
        active: false,
        deleteKeyword: '',
        deleteById: -1
    };

    discardDeleteModal (): void {
        this.deleteModal.active = false;
        this.deleteModal.deleteById = -1;
        this.deleteModal.deleteKeyword = '';
    }

    async confirmDelete (): Promise<void> {
        this.isFetchingData = true;
        await fundRecommendationApi.deleteFundRecommendation(this.deleteModal.deleteById);
        this.fundRecommendationReports = await fundRecommendationApi.getFundRecommendations(this.$store.state.selectedScheme.clientEntityID);
        this.discardDeleteModal();
        this.isFetchingData = false;
    }

    showConfirmDeleteModal ({ exerciseId, report }: { exerciseId: number, report: IFundRecommendationReportDto }): void {
        this.selectedExerciseId = exerciseId;
        this.deleteModal.active = true;
        this.deleteModal.deleteById = exerciseId;
        this.deleteModal.deleteKeyword = report.title;
    }

    private async fetchData (): Promise<void> {
        this.isFetchingData = true;
        if (this.$store.state.selectedScheme) {
            try {
                const [userEmail, fundRecommendationReports, fundRecommendationUsers] = await Promise.all([
                    fundRecommendationApi.getUserEmail(),
                    fundRecommendationApi.getFundRecommendations(this.$store.state.selectedScheme.clientEntityID),
                    fundRecommendationApi.getFundRecommendationUsersForClient(this.$store.state.selectedScheme.clientEntityID),
                ]);

                this.userEmail = userEmail;
                this.fundRecommendationReports = fundRecommendationReports;
                fundRecommendationUsers.assetClassLeads = fundRecommendationUsers.assetClassLeads.map(str => str.toLowerCase());
                fundRecommendationUsers.lcpUsersAssociatedWithScheme = fundRecommendationUsers.lcpUsersAssociatedWithScheme.map(str => str.toLowerCase());
                this.fundRecommendationUsers = fundRecommendationUsers;

                if (!this.fundRecommendationUsers.lcpUsersAssociatedWithScheme.includes(userEmail)) {
                    this.fundRecommendationUsers.lcpUsersAssociatedWithScheme.push(userEmail);
                }

                this.fundRecommendationUsers.lcpUsersAssociatedWithScheme.sort();
                this.fundRecommendationUsers.assetClassLeads.sort();
            } catch (error) {
                this.$store.dispatch('pushNetworkErrorMessage', 'There was an error fetching fund recommendations. Please try again');
            } finally {
                this.isFetchingData = false;
            }
        } else {
            this.$router.push('/');
        }

    }

    public async createNew (): Promise<void> {
        this.isFetchingData = true;
        this.viewAsReadonly = false;
        this.isNewReport = true;
        this.defaultReport.clientId = this.$store.state.selectedScheme.clientEntityID;
        this.defaultReport.auditInfo.contact = this.userEmail;
        this.defaultReport.approximateSizeOfSearch.amount.currency.currencyCode = this.currencyOptions[0];
        this.selectedReport = this.defaultReport;
        this.showFundRecommendationReport = true;
        this.isFetchingData = false;
    }

    public showDetail ({ exerciseId, report, viewAsReadonly }: { exerciseId: number, report: IFundRecommendationReportDto, viewAsReadonly: boolean }): void {
        this.isNewReport = false;
        this.selectedReport = report;
        this.selectedExerciseId = exerciseId;
        this.viewAsReadonly = viewAsReadonly;
        this.showFundRecommendationReport = true;
    }

    async saveNew (report: IFundRecommendationReportDto): Promise<void> {
        this.isFetchingData = true;
        try {
            this.fundRecommendationReports = await fundRecommendationApi.createFundRecommendation(report);
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'There was an error creating the report');
        } finally {
            this.isFetchingData = false;
            this.showFundRecommendationReport = false;
        };
    }

    async update (report: IFundRecommendationReportDto): Promise<void> {
        this.isFetchingData = true;
        try {
            this.fundRecommendationReports = await fundRecommendationApi.updateFundRecommendation(this.selectedExerciseId, report);
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'There was an error updating the report');
        } finally {
            this.isFetchingData = false;
            this.showFundRecommendationReport = false;
        };
    }

    async signOff (report: IFundRecommendationReportDto): Promise<void> {
        this.isFetchingData = true;
        try {
            this.fundRecommendationReports = await fundRecommendationApi.updateFundRecommendation(this.selectedExerciseId, report);
            this.fundRecommendationReports = await fundRecommendationApi.signOffFundRecommendation(this.selectedExerciseId, this.userEmail);
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'There was an error signing off the report');
        } finally {
            this.isFetchingData = false;
            this.showFundRecommendationReport = false;
        };
    }

    async closeEditEntity (): Promise<void> {
        this.showFundRecommendationReport = false;
    }

    public defaultReport : IFundRecommendationReportDto = {
        title: '',
        clientId: '',
        clientBillingCode: '',
        status: FundRecommendationStatus.Draft,
        dates: {
            targetSendDate: dayjs().format(FORMAT_DATE_ISO_SHORT),
            actualSendDate: null
        },
        approximateSizeOfSearch: {
            amount: {
                amount: 0,
                currency: {
                    currencyCode: ''
                }
            },
            allocation: 0
        },
        expectedNumberOfAppointments: 1,
        recommendedFunds: {
            funds: [],
            reasoningForASingleFund: ''
        },
        auditInfo: {
            contact: '',
            signOffPartner: '',
            discussedWithAssetClassLead: false,
            assetClassLead: '',
            assetClassLeadDiscussion: '',
        },
        notes: {
            background: '',
            specificRequirements: ''
        }
    };
}
