var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("section", { staticClass: "form-container" }, [
      _c("h2", { staticClass: "section-title" }, [
        _vm._v("Fund Recommendation Exercise Information"),
      ]),
      _c("label", { staticClass: "label-container" }, [
        _c("span", { staticClass: "label-text" }, [_vm._v("Title:")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.updatedReport.title,
              expression: "updatedReport.title",
            },
          ],
          staticClass: "input-box",
          class: { "invalid-input": _vm.isTitleBlank || _vm.isTitleTooLong },
          attrs: { type: "text", disabled: _vm.viewAsReadonly },
          domProps: { value: _vm.updatedReport.title },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.updatedReport, "title", $event.target.value)
            },
          },
        }),
      ]),
      _vm.isTitleBlank
        ? _c("span", { staticClass: "warning-message" }, [
            _vm._v("Title can't be left blank"),
          ])
        : _vm._e(),
      _vm.isTitleTooLong
        ? _c("span", { staticClass: "warning-message" }, [
            _vm._v("Title can't exceed 100 characters"),
          ])
        : _vm._e(),
      _c("label", { staticClass: "label-container" }, [
        _c("span", { staticClass: "label-text" }, [
          _vm._v('Client billing code ("Client Ref" code from STAR):'),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.updatedReport.clientBillingCode,
              expression: "updatedReport.clientBillingCode",
            },
          ],
          staticClass: "input-box",
          class: { "invalid-input": _vm.isBillingCodeInvalid },
          attrs: { type: "text", disabled: _vm.viewAsReadonly },
          domProps: { value: _vm.updatedReport.clientBillingCode },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.updatedReport,
                "clientBillingCode",
                $event.target.value
              )
            },
          },
        }),
      ]),
      _vm.isBillingCodeInvalid
        ? _c("span", { staticClass: "warning-message" }, [
            _vm._v("Client billing code can't be left blank"),
          ])
        : _vm._e(),
      _c(
        "label",
        { staticClass: "label-container" },
        [
          _c("span", { staticClass: "label-text" }, [
            _vm._v("Target date to send papers to clients:"),
          ]),
          _c("date-picker", {
            staticClass: "custom-date-picker",
            attrs: {
              "value-type": "YYYY-MM-DD",
              format: "DD/MM/YYYY",
              clearable: false,
              disabled: _vm.viewAsReadonly,
            },
            model: {
              value: _vm.updatedReport.dates.targetSendDate,
              callback: function ($$v) {
                _vm.$set(_vm.updatedReport.dates, "targetSendDate", $$v)
              },
              expression: "updatedReport.dates.targetSendDate",
            },
          }),
        ],
        1
      ),
      _c("label", { staticClass: "label-container" }, [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(
            "Background (eg which asset class / classes; if replacement, replacing which manager; new allocation to this asset class; increasing allocation; etc):"
          ),
        ]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.updatedReport.notes.background,
              expression: "updatedReport.notes.background",
            },
          ],
          staticClass: "reasoning-textarea",
          class: { "invalid-input": _vm.isBackgroundInvalid },
          attrs: {
            type: "text",
            rows: "4",
            cols: "50",
            disabled: _vm.viewAsReadonly,
          },
          domProps: { value: _vm.updatedReport.notes.background },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.updatedReport.notes,
                "background",
                $event.target.value
              )
            },
          },
        }),
      ]),
      _vm.isBackgroundInvalid
        ? _c("label", { staticClass: "warning-message" }, [
            _vm._v("Background can't be left blank"),
          ])
        : _vm._e(),
      _c("label", { staticClass: "label-container" }, [
        _c("span", { staticClass: "label-text" }, [
          _vm._v(
            "Specific requirements (eg managers to be excluded; favoured style or approach):"
          ),
        ]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.updatedReport.notes.specificRequirements,
              expression: "updatedReport.notes.specificRequirements",
            },
          ],
          staticClass: "reasoning-textarea",
          class: { "invalid-input": _vm.areSpecificRequirementsInvalid },
          attrs: {
            type: "text",
            rows: "4",
            cols: "50",
            disabled: _vm.viewAsReadonly,
          },
          domProps: { value: _vm.updatedReport.notes.specificRequirements },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.updatedReport.notes,
                "specificRequirements",
                $event.target.value
              )
            },
          },
        }),
      ]),
      _vm.areSpecificRequirementsInvalid
        ? _c("label", { staticClass: "warning-message" }, [
            _vm._v("Specific requirements can't be left blank"),
          ])
        : _vm._e(),
      _c("fieldset", { staticClass: "custom-field-set" }, [
        _vm._m(0),
        _c("label", { staticClass: "label-container" }, [
          _c("span", { staticClass: "label-text" }, [
            _vm._v("Amount in millions:"),
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value:
                    _vm.updatedReport.approximateSizeOfSearch.amount.currency
                      .currencyCode,
                  expression:
                    "updatedReport.approximateSizeOfSearch.amount.currency.currencyCode",
                },
              ],
              staticClass: "currency-input",
              attrs: { name: "currency", disabled: _vm.viewAsReadonly },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.updatedReport.approximateSizeOfSearch.amount.currency,
                    "currencyCode",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            _vm._l(_vm.currencyOptions, function (currency) {
              return _c(
                "option",
                { key: currency, domProps: { value: currency } },
                [_vm._v(_vm._s(currency))]
              )
            }),
            0
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.updatedReport.approximateSizeOfSearch.amount.amount,
                expression:
                  "updatedReport.approximateSizeOfSearch.amount.amount",
              },
            ],
            staticClass: "input-box value-input",
            attrs: {
              type: "number",
              placeholder: "Value",
              disabled: _vm.viewAsReadonly,
            },
            domProps: {
              value: _vm.updatedReport.approximateSizeOfSearch.amount.amount,
            },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.updatedReport.approximateSizeOfSearch.amount,
                  "amount",
                  $event.target.value
                )
              },
            },
          }),
        ]),
        _c("label", { staticClass: "label-container" }, [
          _c("span", { staticClass: "label-text" }, [
            _vm._v("Expected to be split across how many appointments?"),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.updatedReport.expectedNumberOfAppointments,
                expression: "updatedReport.expectedNumberOfAppointments",
              },
            ],
            staticClass: "input-box",
            class: { "invalid-input": _vm.isNumberOfAppointmentsInvalid },
            attrs: {
              type: "number",
              min: "1",
              max: "10",
              disabled: _vm.viewAsReadonly,
            },
            domProps: { value: _vm.updatedReport.expectedNumberOfAppointments },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.updatedReport,
                  "expectedNumberOfAppointments",
                  $event.target.value
                )
              },
            },
          }),
        ]),
        _vm.isNumberOfAppointmentsInvalid
          ? _c("span", { staticClass: "warning-message" }, [
              _vm._v(
                "Number of appointments must be an integer between 1 and 10"
              ),
            ])
          : _vm._e(),
        _c("label", { staticClass: "label-container" }, [
          _c("span", { staticClass: "label-text" }, [
            _vm._v(
              "Total search value as an percentage of total client assets (%):"
            ),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.updatedReport.approximateSizeOfSearch.allocation,
                expression: "updatedReport.approximateSizeOfSearch.allocation",
              },
            ],
            staticClass: "input-box",
            class: { "invalid-input": _vm.isProportionOfAssetsInvalid },
            attrs: {
              type: "number",
              min: "0",
              max: "100",
              disabled: _vm.viewAsReadonly,
            },
            domProps: {
              value: _vm.updatedReport.approximateSizeOfSearch.allocation,
            },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.updatedReport.approximateSizeOfSearch,
                  "allocation",
                  $event.target.value
                )
              },
            },
          }),
        ]),
        _vm.isProportionOfAssetsInvalid
          ? _c("span", { staticClass: "warning-message" }, [
              _vm._v("Proportion of assets must be between 0% and 100%"),
            ])
          : _vm._e(),
      ]),
      _c(
        "fieldset",
        { staticClass: "custom-field-set" },
        [
          _vm._m(1),
          _vm.updatedReport.recommendedFunds.funds.length > 0
            ? _c("table", { staticClass: "funds-table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Name")]),
                    _c("th", [_vm._v("Asset Class")]),
                    _c("th", [_vm._v("LCP Rating")]),
                    _c("th", [_vm._v("Reasoning")]),
                    !_vm.viewAsReadonly
                      ? _c("th", [_vm._v("Actions")])
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.updatedReport.recommendedFunds.funds,
                    function (fund, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(fund.recommendation.name))]),
                        _c("td", [
                          _vm._v(_vm._s(fund.recommendation.assetClass)),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(fund.recommendation.lcpRatingWhenRecommended)
                          ),
                        ]),
                        _c("td", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: fund.recommendation.reasoning,
                                expression: "fund.recommendation.reasoning",
                              },
                            ],
                            staticClass: "reasoning-textarea",
                            attrs: {
                              rows: "2",
                              cols: "50",
                              disabled: _vm.viewAsReadonly,
                            },
                            domProps: { value: fund.recommendation.reasoning },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  fund.recommendation,
                                  "reasoning",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        !_vm.viewAsReadonly
                          ? _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "theme-icon flex flex--row-center",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFund(index)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fal", "trash-alt"] },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
          !_vm.viewAsReadonly
            ? _c(
                "div",
                {
                  staticClass: "add-new-fund",
                  on: { click: _vm.showFundSelectorModal },
                },
                [
                  _c(
                    "div",
                    { staticClass: "theme-icon flex flex--row-center" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "plus"] },
                      }),
                    ],
                    1
                  ),
                  _vm._m(2),
                ]
              )
            : _vm._e(),
          _vm.showFundSelector
            ? _c("BaseModal", {
                staticClass: "fund-selector",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _vm.showFundSelector
                            ? _c("recommended-fund-selector", {
                                attrs: {
                                  initialFundResearchEntityIds:
                                    _vm.getInitialFundResearchEntityIds(),
                                },
                                on: {
                                  "close-modal": _vm.hideFundSelector,
                                  "update-funds": _vm.updateFunds,
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  229761151
                ),
              })
            : _vm._e(),
          _vm.singleFundRecommended()
            ? _c("label", { staticClass: "label-container" }, [
                _c("span", { staticClass: "label-text" }, [
                  _vm._v("Reasoning for recommending single fund:"),
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.updatedReport.recommendedFunds
                          .reasoningForASingleFund,
                      expression:
                        "updatedReport.recommendedFunds.reasoningForASingleFund",
                    },
                  ],
                  staticClass: "reasoning-textarea",
                  class: {
                    "invalid-input": _vm.isSingleFundRecommendedInvalid,
                  },
                  attrs: {
                    type: "text",
                    rows: "4",
                    cols: "50",
                    disabled: _vm.viewAsReadonly,
                  },
                  domProps: {
                    value:
                      _vm.updatedReport.recommendedFunds
                        .reasoningForASingleFund,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.updatedReport.recommendedFunds,
                        "reasoningForASingleFund",
                        $event.target.value
                      )
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.isSingleFundRecommendedInvalid
            ? _c("span", { staticClass: "warning-message" }, [
                _vm._v(
                  "Reason for recommending single fund can't be left blank"
                ),
              ])
            : _vm._e(),
          _vm.areNoFundsRecommended
            ? _c("span", { staticClass: "warning-message" }, [
                _vm._v("At least one fund must be recommended"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("fieldset", { staticClass: "custom-field-set" }, [
        _vm._m(3),
        _c("label", { staticClass: "label-container" }, [
          _c("span", { staticClass: "label-text" }, [_vm._v("Contact email:")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedReport.auditInfo.contact,
                  expression: "updatedReport.auditInfo.contact",
                },
              ],
              staticClass: "input-box",
              attrs: { disabled: _vm.viewAsReadonly },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.updatedReport.auditInfo,
                    "contact",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            _vm._l(
              _vm.fundRecommendationUsers.lcpUsersAssociatedWithScheme,
              function (user) {
                return _c(
                  "option",
                  {
                    key: user,
                    attrs: { label: user },
                    domProps: { value: user },
                  },
                  [_vm._v(_vm._s(user))]
                )
              }
            ),
            0
          ),
        ]),
        _c("label", { staticClass: "label-container" }, [
          _c("span", { staticClass: "label-text" }, [
            _vm._v("Sign-off email:"),
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedReport.auditInfo.signOffPartner,
                  expression: "updatedReport.auditInfo.signOffPartner",
                },
              ],
              staticClass: "input-box",
              attrs: { disabled: _vm.viewAsReadonly },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.updatedReport.auditInfo,
                    "signOffPartner",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            _vm._l(
              _vm.fundRecommendationUsers.lcpUsersAssociatedWithScheme,
              function (user) {
                return _c(
                  "option",
                  {
                    key: user,
                    attrs: { label: user },
                    domProps: { value: user },
                  },
                  [_vm._v(_vm._s(user))]
                )
              }
            ),
            0
          ),
        ]),
        _c("label", { staticClass: "label-container" }, [
          _c("span", { staticClass: "label-text" }, [
            _vm._v("Discussed with asset class lead:"),
          ]),
          _c("div", { staticClass: "radio-buttons" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value:
                    _vm.updatedReport.auditInfo.discussedWithAssetClassLead,
                  expression:
                    "updatedReport.auditInfo.discussedWithAssetClassLead",
                },
              ],
              attrs: {
                type: "radio",
                id: "yes",
                name: "discussed",
                disabled: _vm.viewAsReadonly,
              },
              domProps: {
                value: true,
                checked: _vm._q(
                  _vm.updatedReport.auditInfo.discussedWithAssetClassLead,
                  true
                ),
              },
              on: {
                change: function ($event) {
                  return _vm.$set(
                    _vm.updatedReport.auditInfo,
                    "discussedWithAssetClassLead",
                    true
                  )
                },
              },
            }),
            _c("label", { attrs: { for: "yes" } }, [_vm._v("Yes")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value:
                    _vm.updatedReport.auditInfo.discussedWithAssetClassLead,
                  expression:
                    "updatedReport.auditInfo.discussedWithAssetClassLead",
                },
              ],
              attrs: {
                type: "radio",
                id: "no",
                name: "discussed",
                disabled: _vm.viewAsReadonly,
              },
              domProps: {
                value: false,
                checked: _vm._q(
                  _vm.updatedReport.auditInfo.discussedWithAssetClassLead,
                  false
                ),
              },
              on: {
                change: function ($event) {
                  return _vm.$set(
                    _vm.updatedReport.auditInfo,
                    "discussedWithAssetClassLead",
                    false
                  )
                },
              },
            }),
            _c("label", { attrs: { for: "no" } }, [_vm._v("No")]),
          ]),
        ]),
        _vm.isDiscussedWithAssetClassFalseAndSigningOff
          ? _c("span", { staticClass: "warning-message" }, [
              _vm._v(
                "The report must be discussed with the asset class lead before it can be signed off"
              ),
            ])
          : _vm._e(),
        _c(
          "label",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.updatedReport.auditInfo.discussedWithAssetClassLead,
                expression:
                  "updatedReport.auditInfo.discussedWithAssetClassLead",
              },
            ],
            staticClass: "label-container",
          },
          [
            _c("span", { staticClass: "label-text" }, [
              _vm._v("Asset class lead email:"),
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.updatedReport.auditInfo.assetClassLead,
                    expression: "updatedReport.auditInfo.assetClassLead",
                  },
                ],
                staticClass: "input-box",
                attrs: { disabled: _vm.viewAsReadonly },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.updatedReport.auditInfo,
                      "assetClassLead",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(
                _vm.fundRecommendationUsers.assetClassLeads,
                function (user) {
                  return _c(
                    "option",
                    {
                      key: user,
                      attrs: { label: user },
                      domProps: { value: user },
                    },
                    [_vm._v(_vm._s(user))]
                  )
                }
              ),
              0
            ),
          ]
        ),
        _vm.isAssetClassLeadInvalid
          ? _c("label", { staticClass: "warning-message" }, [
              _vm._v("Asset class lead can't be left blank"),
            ])
          : _vm._e(),
        _c(
          "label",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.updatedReport.auditInfo.discussedWithAssetClassLead,
                expression:
                  "updatedReport.auditInfo.discussedWithAssetClassLead",
              },
            ],
            staticClass: "label-container",
          },
          [
            _c("span", { staticClass: "label-text" }, [
              _vm._v("Summary of discussion:"),
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedReport.auditInfo.assetClassLeadDiscussion,
                  expression:
                    "updatedReport.auditInfo.assetClassLeadDiscussion",
                },
              ],
              staticClass: "reasoning-textarea",
              class: { "invalid-input": _vm.isAssetClassLeadDiscussionInvalid },
              attrs: {
                type: "text",
                rows: "4",
                cols: "50",
                disabled: _vm.viewAsReadonly,
              },
              domProps: {
                value: _vm.updatedReport.auditInfo.assetClassLeadDiscussion,
              },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.updatedReport.auditInfo,
                    "assetClassLeadDiscussion",
                    $event.target.value
                  )
                },
              },
            }),
          ]
        ),
        _vm.isAssetClassLeadDiscussionInvalid
          ? _c("label", { staticClass: "warning-message" }, [
              _vm._v("Summary of discussion can't be left blank"),
            ])
          : _vm._e(),
      ]),
      _vm.updatedReport.recommendedFunds.funds.length > 0
        ? _c("fieldset", { staticClass: "custom-field-set" }, [
            _vm.updatedReport.recommendedFunds.funds.length > 0
              ? _c("legend", { staticClass: "sub-section-title" }, [
                  _c("strong", [_vm._v("Outcome")]),
                ])
              : _vm._e(),
            _vm.updatedReport.recommendedFunds.funds.length > 0
              ? _c(
                  "label",
                  { staticClass: "label-container" },
                  [
                    _c("span", { staticClass: "label-text" }, [
                      _vm._v("Date sent to client:"),
                    ]),
                    _c("DatePicker", {
                      staticClass: "custom-date-picker",
                      class: {
                        "invalid-input":
                          _vm.isDateSentToClientNullAndSigningOff,
                      },
                      attrs: {
                        valueType: "YYYY-MM-DD",
                        format: "DD/MM/YYYY",
                        clearable: false,
                        disabled: _vm.viewAsReadonly,
                      },
                      model: {
                        value: _vm.updatedReport.dates.actualSendDate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.updatedReport.dates,
                            "actualSendDate",
                            $$v
                          )
                        },
                        expression: "updatedReport.dates.actualSendDate",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDateSentToClientNullAndSigningOff
              ? _c("label", { staticClass: "warning-message" }, [
                  _vm._v(
                    "The date the report was sent to the client must be specified before it can be signed off"
                  ),
                ])
              : _vm._e(),
            _vm.updatedReport.recommendedFunds.funds.length > 0
              ? _c("table", { staticClass: "funds-table" }, [
                  _vm._m(4),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.updatedReport.recommendedFunds.funds,
                      function (fund, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(fund.recommendation.name))]),
                          _c(
                            "td",
                            { attrs: { disabled: _vm.viewAsReadonly } },
                            [
                              _c("div", { staticClass: "radio-buttons" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: fund.outcome.selectedByClient,
                                      expression:
                                        "fund.outcome.selectedByClient",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "yes-" + index,
                                    disabled: _vm.viewAsReadonly,
                                  },
                                  domProps: {
                                    value: true,
                                    checked: _vm._q(
                                      fund.outcome.selectedByClient,
                                      true
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        fund.outcome,
                                        "selectedByClient",
                                        true
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "yes-" + index } },
                                  [_vm._v("Yes")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: fund.outcome.selectedByClient,
                                      expression:
                                        "fund.outcome.selectedByClient",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "no-" + index,
                                    disabled: _vm.viewAsReadonly,
                                  },
                                  domProps: {
                                    value: false,
                                    checked: _vm._q(
                                      fund.outcome.selectedByClient,
                                      false
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        fund.outcome,
                                        "selectedByClient",
                                        false
                                      )
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "no-" + index } }, [
                                  _vm._v("No"),
                                ]),
                              ]),
                            ]
                          ),
                          _c("td", [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: fund.outcome.reasonForOutcome,
                                  expression: "fund.outcome.reasonForOutcome",
                                },
                              ],
                              staticClass: "reasoning-textarea",
                              attrs: {
                                rows: "2",
                                cols: "50",
                                disabled: _vm.viewAsReadonly,
                              },
                              domProps: {
                                value: fund.outcome.reasonForOutcome,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    fund.outcome,
                                    "reasonForOutcome",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
            _vm.isSelectedByClientNullAndSigningOff
              ? _c("label", { staticClass: "warning-message" }, [
                  _vm._v(
                    "The outcome of each fund must be specified before the report can be signed off"
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
    !_vm.showFundSelector
      ? _c("section", { staticClass: "confirmation-section" }, [
          _vm.isNewReport
            ? _c(
                "div",
                { staticClass: "confirmation flex" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { type: "button" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      staticClass: "primary-btn",
                      attrs: { type: "button" },
                      on: { click: _vm.validateAndCreate },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              )
            : !_vm.viewAsReadonly
            ? _c(
                "div",
                { staticClass: "confirmation flex" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { type: "button" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      staticClass: "primary-btn",
                      attrs: { type: "button" },
                      on: { click: _vm.validateAndUpdate },
                    },
                    [_vm._v("Update")]
                  ),
                  _vm.isSignOffPartner
                    ? _c(
                        "BaseButton",
                        {
                          staticClass: "primary-btn",
                          attrs: { type: "button" },
                          on: { click: _vm.validateAndSignOff },
                        },
                        [_vm._v("Sign Off")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "confirmation flex" },
                [
                  _c(
                    "BaseButton",
                    {
                      staticClass: "primary-btn",
                      attrs: { type: "button" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "sub-section-title" }, [
      _c("strong", [_vm._v("Approximate Search Size")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "sub-section-title" }, [
      _c("strong", [_vm._v("Recommended Funds")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("strong", [_vm._v("Add new fund")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "sub-section-title" }, [
      _c("strong", [_vm._v("Audit Information")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _c("th", [_vm._v("Selected By Client?")]),
        _c("th", [_vm._v("Reasoning")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }