var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-container" },
    [
      _vm.isFetchingData
        ? _c("LoadingOverlay")
        : [
            !_vm.showFundRecommendationReport
              ? _c(
                  "div",
                  { staticClass: "add-report", on: { click: _vm.createNew } },
                  [
                    _c(
                      "div",
                      { staticClass: "theme-icon flex flex--row-center" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "plus"] },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("Create new fund recommendation form")]),
                  ]
                )
              : _vm._e(),
            _vm.showFundRecommendationReport
              ? _c("FundRecommendationReport", {
                  attrs: {
                    fundRecommendationReport: _vm.selectedReport,
                    clientId: _vm.clientId,
                    currencyOptions: _vm.currencyOptions,
                    fundRecommendationUsers: _vm.fundRecommendationUsers,
                    isNewReport: _vm.isNewReport,
                    userEmail: _vm.userEmail,
                    viewAsReadonly: _vm.viewAsReadonly,
                  },
                  on: {
                    close: _vm.closeEditEntity,
                    "save-new": _vm.saveNew,
                    update: _vm.update,
                    "sign-off": _vm.signOff,
                  },
                })
              : _vm._e(),
            !_vm.showFundRecommendationReport
              ? _c("FundRecommendationReports", {
                  attrs: {
                    fundRecommendationReports: _vm.fundRecommendationReports,
                  },
                  on: {
                    showDetail: _vm.showDetail,
                    showConfirmDeleteModal: _vm.showConfirmDeleteModal,
                  },
                })
              : _vm._e(),
            _vm.deleteModal.active
              ? _c("BaseModal", {
                  staticClass: "show-modal",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c(
                              "ConfirmDeletion",
                              {
                                attrs: {
                                  "delete-keyword":
                                    _vm.deleteModal.deleteKeyword,
                                },
                                on: {
                                  discardDeleteModal: _vm.discardDeleteModal,
                                  confirmDelete: _vm.confirmDelete,
                                },
                              },
                              [
                                _c("p", { staticClass: "delete-text" }, [
                                  _vm._v(
                                    "You're about to delete this fund exercise, this cannot be undone."
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    4083990137
                  ),
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }