












































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import { IFundRecommendationReportDto } from '@/interfaces/dto/IFundRecommendationReportDto';
import RecommendedFundSelector from '@/components/RecommendedFundSelector.vue';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import dayjs from 'dayjs';
import { FundRecommendationStatus } from '@/enums/FundRecommendationStatus';


@Component({
    components: {
        DatePicker,
        RecommendedFundSelector,
        BaseButton
    },
})
export default class FundRecommendationReports extends Vue {
    @Prop({ required: true }) fundRecommendationReports: { [key: number]: IFundRecommendationReportDto };

    public dayJs = dayjs;

    @Emit('showDetail')
    showDetail (exerciseId: number, report: IFundRecommendationReportDto, viewAsReadonly: boolean): { exerciseId: number, report: IFundRecommendationReportDto, viewAsReadonly: boolean } {
        return { exerciseId, report, viewAsReadonly };
    }

    @Emit('showConfirmDeleteModal')
    showConfirmDeleteModal  (exerciseId: number, report: IFundRecommendationReportDto): { exerciseId: number, report: IFundRecommendationReportDto } {
        return { exerciseId, report };
    }

    isSignedOff (report: IFundRecommendationReportDto) : boolean {
        return report.status === FundRecommendationStatus.Final;
    }

    getStatusString (status: FundRecommendationStatus): string {
        switch (status) {
        case FundRecommendationStatus.Draft:
            return 'Draft';
        case FundRecommendationStatus.Final:
            return 'Final';
        default:
            return 'Unknown';
        }
    }
}

